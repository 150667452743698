import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  MobileStepper,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useBookContext } from "../../../context/BookContext";
import { useEffect, useState } from "react";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { CollapseMessage } from "../../../components/collapseMessage";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import { getToday, setTimezone } from "../../../components/function";
import { onFindCar } from "../../../apiCallFunctions/onFindCar";

export const FindCarMulti = () => {
  const navigate = useNavigate();
  const { isPC } = useAuth();
  const { book } = useBookContext();

  const [finding, setFinding] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const today = getToday();
  const minDate = today.hour() > 18 ? today.add(2, "day") : today.add(1, "day");

  const [value, setValue] = useState<Dayjs | null>(minDate);
  const [selectedDays, setSelectedDays] = useState<Set<string>>(new Set());

  const minTime = today.set("h", 7).set("m", 0).set("second", 0);
  const maxTime = today.set("h", 19).set("m", 0).set("second", 59);
  const [time, setTime] = useState<Dayjs>(minTime);
  const [hour, setHour] = useState("07");
  const [min, setMin] = useState("00");

  const hourLists = new Array(13)
    .fill(0)
    .map((_, index) => String(index + 7).padStart(2, "0"));
  const minLists = new Array(60)
    .fill(0)
    .map((_, index) => String(index).padStart(2, "0"));

  const [helpDiagnosis, setHelpDiagnosis] = useState(
    book.companion.helpDiagnosis
  );

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }
  }, []);

  const findCar = async () => {
    if (helpDiagnosis === "") {
      setMessage("병원 예약 시간과 내용을 입력해주세요.");
      return;
    }

    if (selectedDays.size <= 0) {
      setMessage("날짜를 선택해주세요.");
      return;
    }
    setFinding(true);

    book.companion.helpDiagnosis = helpDiagnosis;
    let bookDate: string[] = [];
    const selectedDaysList: string[] = Array.from(selectedDays.values()).sort();
    const hourMin = hour + min;
    for (let day of selectedDaysList) {
      bookDate.push(day + hourMin);
    }

    try {
      onFindCar(book, bookDate, book.type).then((result) => {
        setFinding(false);
        navigate("../findcarMultiResult", { state: { result, bookDate } });
      });
    } catch (error: any) {
      setFinding(false);
      setMessage(error.response.data.message);
    }
  };

  const SelectedDays = (
    props: PickersDayProps<Dayjs> & { selectedDays?: Set<string> }
  ) => {
    const {
      selectedDays = new Set([]),
      day,
      outsideCurrentMonth,
      ...rest
    } = props;

    const isSelectedDay =
      !outsideCurrentMonth && selectedDays.has(day.format("YYMMDD"));

    return (
      <PickersDay
        {...rest}
        outsideCurrentMonth={outsideCurrentMonth}
        selected={isSelectedDay}
        day={day}
      />
    );
  };

  const onDateChanged = (date: Dayjs | null) => {
    if (date === null) return;
    const tzDate = setTimezone(date);
    setValue(tzDate);

    const dateString = date.format("YYMMDD");
    const newSet = new Set(selectedDays);

    if (selectedDays.has(dateString)) {
      newSet.delete(dateString);
    } else {
      newSet.add(dateString);
    }
    setSelectedDays(newSet);
  };

  return (
    <Box p={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>
          원하시는 날짜 및 시간을 선택해주세요
        </Typography>
        <Typography color={"primary"}>당일 예약은 불가능합니다.</Typography>
        <Typography color={"primary"}>(3개월 이내 예약만 가능)</Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <DateCalendar
          value={value}
          minDate={minDate}
          maxDate={today.add(89, "day")}
          onChange={(date) => onDateChanged(date)}
          slots={{ day: SelectedDays }}
          slotProps={{ day: { selectedDays } as any }}
        />
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Typography
            width={isPC ? "160px" : "80px"}
            sx={{ wordBreak: "keep-all" }}
          >
            목적지 차량 도착시간
          </Typography>

          <Box display={"flex"} alignItems={"center"} gap={1}>
            <FormControl>
              <Select
                value={hour}
                sx={{ ".MuiInputBase-input": { px: 2, py: 1 } }}
                onChange={(event) => {
                  const h = event.target.value as unknown as string;
                  const t = h + min;
                  if (t < minTime.format("HHmm")) {
                    setHour("07");
                    setMin("00");
                    setTime(minTime);
                  } else if (t > maxTime.format("HHmm")) {
                    setHour("19");
                    setMin("00");
                    setTime(maxTime);
                  } else {
                    setHour(h);
                    setTime(time.set("h", Number(h)));
                  }
                }}
              >
                {hourLists.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            시
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={1}>
            <FormControl>
              <Select
                value={min}
                sx={{ ".MuiInputBase-input": { px: 2, py: 1 } }}
                onChange={(event) => {
                  const m = event.target.value as unknown as string;
                  const t = hour + m;
                  if (t < minTime.format("HHmm")) {
                    setHour("07");
                    setMin("00");
                    setTime(minTime);
                  } else if (t > maxTime.format("HHmm")) {
                    setHour("19");
                    setMin("00");
                    setTime(maxTime);
                  } else {
                    setMin(m);
                    setTime(time.set("m", Number(m)));
                  }
                }}
              >
                {minLists.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    disabled={hour >= "19" && item !== "00"}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            분
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 1,
            ml: 0,
            fontWeight: 600,
            fontSize: "0.9rem",
            color: "commonColor.warning",
          }}
        >
          ※ 병원 예약시간보다 여유롭게 설정해주세요.
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>병원 예약 시간과 내용</Typography>
        <Box display={"flex"} ml={1} mt={1}>
          <TextField
            variant="standard"
            value={helpDiagnosis}
            placeholder={"예) 14:00 예약 내용"}
            onChange={(event) => setHelpDiagnosis(event.target.value)}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>

      {book.bookDrive.type === "round-trip" && (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography
                width={isPC ? "160px" : "80px"}
                sx={{ wordBreak: "keep-all" }}
              >
                목적지에서 머무는 시간
              </Typography>

              <Typography
                sx={{ ml: 2 }}
              >{`${book.companion.stayHour}시간 ${book.companion.stayMin}분`}</Typography>
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography
                width={isPC ? "160px" : "80px"}
                sx={{ wordBreak: "keep-all" }}
              >
                차량 출발시간
              </Typography>

              <Typography sx={{ ml: 2 }}>{`${time
                .add(book.companion.stayHour, "h")
                .add(book.companion.stayMin, "m")
                .format("HH:mm")}`}</Typography>
            </Box>
          </Paper>
        </>
      )}

      <CollapseMessage
        type="info"
        message={message || ""}
        open={message !== undefined}
        onClose={() => setMessage(undefined)}
        sx={{ mt: 2 }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={finding}
        sx={{ mt: 2, fontWeight: "600", fontSize: "1.1rem" }}
        onClick={() => findCar()}
      >
        {finding ? "검색 중" : "정기 예약 검색"}
      </Button>

      {finding && <LinearProgress sx={{ mt: -0.5, borderRadius: 24 }} />}

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={4}
        nextButton={
          <Button size="small" disabled={true} onClick={() => {}}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => navigate("../checkinfo", { replace: true })}
          >
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
