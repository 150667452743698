import { useLocation, useNavigate } from "react-router-dom";
import { BookState, useBookContext } from "../../../context/BookContext";
import { FindCarResult } from "../../../apiCallFunctions/onFindCar";
import { usePreventRefresh } from "../../../hooks/usePreventRefresh";
import { useEffect, useState } from "react";
import { Button, Grid, MobileStepper, Paper, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import { DialogYesNoAction } from "../../../components/dialog";
import { onRemoveBook } from "../../../apiCallFunctions/onRemoveBook";
import { Loading } from "../../../components/loading";
import {
  getDayjsParseFormat,
  getTimeString,
} from "../../../components/function";
import { useResetTimer } from "../../../hooks/useResetTimer";
import { onRenewBook } from "../../../apiCallFunctions/onRenewBook";

export const FindCarResultPage = () => {
  const navigate = useNavigate();
  const { state }: { state?: { result: FindCarResult[]; bookDate: string } } =
    useLocation();
  const [selected, setSelected] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { updateBook } = useBookContext();

  usePreventRefresh();

  useEffect(() => {
    if (state === null || state === undefined) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (state) {
      setSelected(state.result[0].uid);
    }
  }, [state]);

  useEffect(() => {
    onResetThrottle();
    onRenewThrottle();
  }, [selected]);

  // creationDate 갱신. 2분마다 동작
  const onRenew = async () => {
    let uidList: string[] = [];
    for (let item of state!.result) {
      if (item.ok === "ok" || item.ok === "alter") {
        uidList.push(item.uid);
      }
    }

    try {
      await onRenewBook(uidList);
    } catch (error) {
      console.log(error);
    }
  };

  // 타임아웃 시 임시예약 모두 삭제 후 페이지 이동
  const onClear = async (callByTimer: boolean = false) => {
    setLoading(true);

    try {
      await removeTempBooks("");
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    navigate("/reservation", { replace: true, state: { callByTimer } });
  };

  const { timer, onResetThrottle, onRenewThrottle } = useResetTimer(
    onRenew,
    onClear
  );

  const getBook = (uid: string) => {
    return new Promise<BookState>((resolve, reject) => {
      getDoc(doc(firestore, "books", uid))
        .then((value) => {
          if (value.exists()) {
            resolve(value.data() as BookState);
          } else {
            reject("no data");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // uid를 제외한 모든 임시 예약 데이터 삭제
  const removeTempBooks = async (uid: string) => {
    // remove all temp books
    let uidList: string[] = [];
    for (let item of state!.result) {
      if (item.uid !== "" && item.uid !== uid) uidList.push(item.uid);
    }

    if (uidList.length > 0) {
      return onRemoveBook(uidList);
    }
  };

  // 선택된 하나의 임시예약만 남기고 나머지는 삭제한 후 다음 페이지로 이동
  const onNext = async () => {
    setOpenDialog(false);
    if (selected !== "") {
      try {
        setLoading(true);
        await removeTempBooks(selected);
        const book = await getBook(selected);
        updateBook(book);
        setLoading(false);
        navigate("../patient", { replace: true });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const onPrev = async () => {
    setLoading(true);
    try {
      await removeTempBooks("");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    navigate("../findcar", { replace: true });
  };

  return state && state.result ? (
    <>
      {loading && <Loading open={loading} />}
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>
      <HeaderText st={state} />
      <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
        <Grid container spacing={2}>
          {state.result.map(
            ({ ok, uid, date }) =>
              ok !== "error" && (
                <Grid key={uid + date} item pc={6} mobile={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      py: 2,
                      px: 2,
                      textAlign: "center",
                      cursor: "pointer",
                      color:
                        uid === ""
                          ? "primary"
                          : uid === selected
                          ? "white"
                          : "primary",
                      bgcolor:
                        uid === ""
                          ? "primary"
                          : uid === selected
                          ? "commonColor.main"
                          : "primary",
                    }}
                    onClick={() => setSelected(uid)}
                  >
                    <Typography
                      fontWeight={
                        uid === "" ? 400 : uid === selected ? 600 : 400
                      }
                    >{`${getDayjsParseFormat(date, "YYMMDDHHmm").format(
                      "ll(ddd) HH:mm"
                    )} 목적지 도착`}</Typography>
                  </Paper>
                </Grid>
              )
          )}
        </Grid>
      </Paper>
      {state.result[0].ok === "alter" && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
          <Typography color={"commonColor.warning"}>
            다른 시간에 예약을 원하시면 이전 화면에서 다른 시간을 검색하시거나,
            고객센터(070-5088-2000)로 문의주세요
          </Typography>
        </Paper>
      )}

      <DialogYesNoAction
        title={"이동 확인"}
        color="alert"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={() => onNext()}
      >
        <Typography>
          다음 단계로 이동 시, 더 이상 기존에 입력한 정보의 수정이 불가능합니다.
        </Typography>
        <Typography>수정이 필요할 경우 새로 예약해주세요</Typography>
      </DialogYesNoAction>

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={4}
        nextButton={
          <Button
            size="small"
            disabled={selected === "" || loading}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" disabled={loading} onClick={() => onPrev()}>
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </>
  ) : (
    <></>
  );
};

const HeaderText = ({
  st,
}: {
  st: { result: FindCarResult[]; bookDate: string };
}) => {
  return (
    <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
      {st.result[0].ok === "ok" ? (
        <>
          <Typography color={"primary"}>
            희망하시는 시간에 예약이 가능합니다
          </Typography>
          <Typography color={"primary"}>
            계속 진행하시려면 다음 버튼을 눌러주세요
          </Typography>
        </>
      ) : st.result[0].ok === "alter" ? (
        <>
          <Typography color={"commonColor.warning"}>
            {`${getDayjsParseFormat(st.bookDate, "YYMMDDHHmm").format(
              "ll(ddd) HH:mm"
            )}에는 예약이 불가능합니다`}
          </Typography>
          <Typography color={"primary"}>
            아래의 시간에는 예약이 가능합니다. 예약을 원하시면 다음 버튼을
            눌러주세요.
          </Typography>
          <Typography color={"primary"}>
            2개 이상의 시간이 제안된 경우에는 원하시는 시간을 선택하고 다음
            버튼을 눌러주세요.
          </Typography>
        </>
      ) : (
        <>
          <Typography color={"commonColor.alert"}>
            {`${getDayjsParseFormat(st.bookDate, "YYMMDDHHmm").format(
              "ll(DDD) HH:mm"
            )}에는 예약이 불가능합니다`}
          </Typography>
          <Typography color={"commonColor.alert"}>
            다른 시간에 예약을 원하시면 이전 화면에서 다른 시간을 검색하시거나,
            고객센터(070-5088-2000)로 문의주세요
          </Typography>
        </>
      )}
    </Paper>
  );
};
