import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  BookDrive,
  BookDriveType,
  FloorOption,
  NetsService,
  NetsType,
  floorOptions,
  useBookContext,
} from "../../../context/BookContext";
import { CollapseMessage } from "../../../components/collapseMessage";

export const Nets = () => {
  const navigate = useNavigate();
  const init: string | undefined = useLocation().state;

  const {
    book: { nets, bookDrive },
    book,
    updateNets,
    updateBookDrive,
  } = useBookContext();
  const [netsType, setNetsType] = useState<NetsType | "">(
    init ? "" : nets.type
  );
  const [bookDriveType, setBookDriveType] = useState<BookDriveType>(
    bookDrive.type
  );

  const [elevator, setElevator] = useState<boolean>(nets.elevator);
  const [floor, setFloor] = useState<FloorOption>(nets.floor);
  const [bedwheel, setBedwheel] = useState(nets.bedwheel);
  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }
  }, []);

  const onNavigate = (navToNext: boolean = true) => {
    setMessage(undefined);
    if (navToNext) {
      if (netsType === "") {
        setMessage("네츠 서비스를 선택해주세요");
        return;
      }

      if (netsType === "wheel-plus" && floor.index !== 0) {
        setMessage("6층 이상인 경우 고객센터로 문의주세요");
        return;
      }

      const newNets: NetsService = {
        ...nets,
        type: netsType,
        elevator,
        floor,
        bedwheel,
      };
      updateNets(newNets);

      const newBookDrive: BookDrive = { ...bookDrive, type: bookDriveType };
      updateBookDrive(newBookDrive);

      navigate(navToNext ? "../address" : "../", { replace: !navToNext });
    } else {
      navigate("../", { replace: true });
    }
  };

  return (
    <Box p={1}>
      <Paper
        elevation={3}
        sx={{
          py: 2,
          px: 2,
          mt: 2,
          "& > img": { maxWidth: "100%", objectFit: "cover" },
        }}
      >
        {/* <img src="/images/price_1.jpg" alt="" /> */}
        {bookDriveType === "round-trip" ? (
          <img src="/images/nets_roundtrip.jpg" alt="" />
        ) : (
          <img src="/images/nets_oneway.jpg" alt="" />
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>왕복/편도 선택</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={bookDriveType}
            onChange={(_, value) => {
              const type = value as unknown as BookDriveType;
              setBookDriveType(type);
              if (type === "round-trip" && netsType === "move")
                setNetsType("wheel-plus");
            }}
          >
            <FormControlLabel
              value="round-trip"
              control={<Radio size="small" />}
              label="왕복"
            />
            <FormControlLabel
              value="one-way"
              control={<Radio size="small" />}
              label="편도"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>네츠 서비스 선택</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={netsType}
            onChange={(_, value) => {
              setMessage(undefined);
              const type = value as NetsType;
              setNetsType(type);
              setElevator((value as NetsType) === "wheel-plus" ? false : true);
              if (type === "move") setBedwheel(false);
            }}
          >
            <FormControlLabel
              value="wheel-plus"
              control={<Radio size="small" />}
              label="네츠 휠체어플러스"
            />
            {netsType === "wheel-plus" && (
              <Typography color={"primary"} ml={3}>
                {bookDriveType === "one-way"
                  ? "계단이동 + 차량이동 + 베드투베드"
                  : "계단이동 + 차량이동 + 원내동행 + 베드투베드"}
              </Typography>
            )}

            <FormControlLabel
              value="wheel"
              control={<Radio size="small" />}
              label="네츠 휠체어"
            />

            {netsType === "wheel" && (
              <Typography color={"primary"} ml={3}>
                {bookDriveType === "one-way"
                  ? "차량이동 + 베드투베드"
                  : "차량이동 + 원내동행 + 베드투베드"}
              </Typography>
            )}

            {bookDriveType === "one-way" && (
              <>
                <FormControlLabel
                  value="move"
                  control={<Radio size="small" />}
                  label="네츠 무브"
                />
                {netsType === "move" && (
                  <Typography color={"primary"} ml={3}>
                    차량이동 (서울 내, 택시처럼 이동만 가능)
                  </Typography>
                )}
              </>
            )}
          </RadioGroup>
        </FormControl>
      </Paper>

      {netsType === "wheel-plus" && (
        <>
          {/* <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>엘리베이터</Typography>
            <FormControl sx={{ pl: 1, pt: 1 }}>
              <RadioGroup
                value={elevator}
                onChange={(_, value) => setElevator(value === "true")}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="있음"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="없음"
                />
              </RadioGroup>
            </FormControl>
          </Paper> */}

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography sx={{ wordBreak: "keep-all" }}>
              계단리프트 필요한 층수 (편도 기준, 왕복시 x2)
            </Typography>

            <FormControl sx={{ pl: 1, pt: 1 }}>
              <RadioGroup
                value={floor.index}
                onChange={(_, value) =>
                  setFloor(floorOptions[value as unknown as number])
                }
              >
                <FormControlLabel
                  label={`${floorOptions[0].label} (기본 제공)`}
                  value={floorOptions[0].index}
                  control={<Radio size="small" />}
                />
                <FormControlLabel
                  label={`${floorOptions[1].label} (+ ${(
                    floorOptions[1].value * 2
                  ).toLocaleString()}원)`}
                  value={floorOptions[1].index}
                  control={<Radio size="small" />}
                />
                {floor.index === 1 && (
                  <Typography color={"commonColor.alert"} sx={{ ml: 3 }}>
                    고객센터로 예약요청 070-5088-2000
                  </Typography>
                )}
                <FormControlLabel
                  label={`${floorOptions[2].label} (+ ${(
                    floorOptions[2].value * 2
                  ).toLocaleString()}원)`}
                  value={floorOptions[2].index}
                  control={<Radio size="small" />}
                />
                {floor.index === 2 && (
                  <Typography color={"commonColor.alert"} sx={{ ml: 3 }}>
                    고객센터로 예약요청 070-5088-2000
                  </Typography>
                )}
                <FormControlLabel
                  label={floorOptions[3].label}
                  value={floorOptions[3].index}
                  control={<Radio size="small" />}
                />
                {floor.index === 3 && (
                  <Typography color={"commonColor.alert"} sx={{ ml: 3 }}>
                    서비스 불가능
                  </Typography>
                )}
              </RadioGroup>
            </FormControl>
          </Paper>
        </>
      )}

      {netsType !== "move" && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography>침대형 거상 휠체어 사용</Typography>
          <FormControl sx={{ pl: 1, pt: 1 }}>
            <RadioGroup
              value={bedwheel}
              onChange={(_, value) => setBedwheel(value === "true")}
            >
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label="미신청"
              />
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label="신청 (+ 11,000원)"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
      )}

      <CollapseMessage
        type="warning"
        open={message !== undefined}
        message={message || ""}
        onClose={() => setMessage(undefined)}
        sx={{ mt: 2, mb: 0 }}
      />

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={0}
        nextButton={
          <Button
            size="small"
            onClick={() => onNavigate()}
            disabled={floor.index !== 0}
          >
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={() => onNavigate(false)}>
            <KeyboardArrowLeft />
            이전
          </Button>
        }
        sx={{ mt: 2 }}
      />
    </Box>
  );
};
